<template>
  <div  class="ma-md-5 mb-16 px-0 " >


    <div class="d-flex">
      <v-btn icon small class="px-4 mt-6" to="/profile/menu" v-if="$vuetify.breakpoint.mobile">
        <v-icon size=20>mdi-arrow-left</v-icon>
      </v-btn>

      <div class="text-h6 font-weight-bold my-6">我的推文</div>
    </div>

    <v-data-table
      :headers="headers"
      :items="list"
      hide-default-footer
      class="elevation-0"
    >
      <template v-slot:item.op="{ item }">

        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <div  class="pa-5 text-center" v-if="unshowCount>0">
      <v-btn  dark outlined width="30%" tile class="main-btn" @click="page++;getlist()">{{showmore}}</v-btn>
    </div>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">确认要删除这条推文吗?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete=false">取消</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">确认</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>



import topicApi from '@/api/topic'
export default {


  data: () => ({
    dialogDelete:false,
    editedIndex:0,
    itemid : 0,
    iteminfo : null,
    list: [],
    page:1,
    showmore: '查看更多',
    unshowCount : 0,
    headers: [
      {
        text: '标题',
        align: 'start',
        value: 'title',
      },
      { text: '创作时间', value: 'create_time_str' },
      { text: '状态', value: 'status_str' },
      { text: '', value: 'op',sortable: false }
    ],

  
    //
  }),
  computed: {
    
  },
  created() {

    this.getlist()

    
  },
  methods: {
    homesearch() {
      alert(3)
    },
    getlist() {
      let p = {page_index:this.page}
      topicApi.mytopic(p).then(res => {
        res.data.data.forEach(item => {
          this.list.push(item)
        })
        this.unshowCount =  res.data.total - this.list.length
        
      })
    },
    deleteItem (item) {
      this.editedIndex = item.topic_id
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      console.log(this.editedIndex)
      topicApi.removetopic({topicid:this.editedIndex}).then(res => {
        this.dialogDelete = false
        if (res.data==1) {
          this.list.forEach((item,k) => {
          
            if (item.topic_id==this.editedIndex) {
              this.list.splice(k,1)
            }
          })
        } 
        
      })
    }
    
  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  
.goods-item {
  cursor: pointer;
}



</style>

<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'

  @media #{map-get($display-breakpoints, 'md-and-up')}
    .categorybanner .v-image__image--cover
      background-position : center

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    .categorybanner .v-image__image--cover
      background-position : 17% !important
  
  .v-card.on-hover.theme--dark
    background-color: rgba(#FFF, 0.8)
    >.v-card__text
      color: #000000

</style>